import React, {useCallback, useEffect, useState} from "react";
import {
    Alert,
    Box,
    Button,
    FormControlLabel,
    Icon,
    IconButton,
    LinearProgress,
    Popover,
    Rating, Snackbar,
    Switch,
    Typography
} from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowsProp,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarExport
} from '@mui/x-data-grid';
import {styled} from "@mui/material/styles";
import {LocationSource} from "../../Requests/LocationInfoRequests";
import {Review, useGetReviews, useGetReviewsCount} from "../../Requests/ReviewRequests";
import {useStore} from "effector-react";
import {$selectedSubject} from "../Dashboard";
import useMediaQuery from '@mui/material/useMediaQuery';
import RateReviewIcon from '@mui/icons-material/RateReview';
import {ReviewDialog} from "../../Components/ReviewDialog";
import {createEvent} from "effector";
import {useApolloClient} from "@apollo/client";


const langToUri: any = {
    '': '',
    'en': 'https://flagcdn.com/w20/us.png'
}

const onReplyClick = createEvent<Review>()

const columns: GridColDef[] = [
    { field: 'created', headerName: 'Created', hideable: false, 
        renderCell: (params: GridRenderCellParams<number>) => {
            const date = new Date((params.value ?? 0) * 1000)
            return (<>{date.toLocaleString()}</>)
        },
    },
    { field: 'source', headerName: 'Source', width: 70,
        renderCell: (params: GridRenderCellParams<string>) => {
            switch (params.value)
            {
                case LocationSource[LocationSource.GOOGLE_MAP]:
                    return (<img loading='lazy' width={25} src='https://www.vectorlogo.zone/logos/google_maps/google_maps-icon.svg' />)
                default:
                    return (<>?</>)
            }
        },
        align: "center"
    },
    { field: 'langCode', headerName: 'Lang', width: 55,
        renderCell: (params: GridRenderCellParams<string>) => {
            return (<img loading='lazy' width={25} src={langToUri[params.value ?? '']} />)
        },
        align: "center"
    },
    { field: 'author', headerName: 'Author', sortable: false,
        minWidth: 100, maxWidth: 300
    },
    { field: 'rating', headerName: 'Rating', hideable: false, 
        renderCell: (params: GridRenderCellParams<number>) => 
            (<Rating sx={{ scale: '0.72' }} value={params.value} readOnly={true} />), align: 'center', 
        width: 100
    },
    { field: 'text', headerName: 'Text', hideable: false, 
        filterable: false, sortable: false, disableColumnMenu: true,
        flex: 1, minWidth: 200
    },
    { field: 'can_reply', 
        headerName: 'Action', width: 70, align: 'center', sortable: false,
        renderCell: params => (<IconButton onClick={() => onReplyClick(params.row)} disabled={(params.row.reply?.length ?? 0) > 0}><RateReviewIcon /></IconButton>) },
    { field: 'status', headerName: 'Status', width: 70 },
    { field: 'reply', headerName: 'Reply', minWidth: 100 }
];

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
    marginHeight: 25
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>No Rows</Box>
        </StyledGridOverlay>
    );
}

interface PaginationState {
    page: number,
    pageSize: number
}

export const ReviewsContent = () => {
    const mediaQuery = useMediaQuery('(max-width:425px) or (max-height:425px)');
    const [ replySentAlert, setReplySentAlert ] = useState<boolean>(mediaQuery)

    const apolloClient = useApolloClient();
    const [ compact, setCompact ] = useState<boolean>(mediaQuery)
    
    const selectedSubject = useStore($selectedSubject)
    
    const [ state, setState ] = useState<PaginationState>({
        page: 0,
        pageSize: 200
    });
    
    //const reviewsCount = useGetReviewsCount(selectedSubject?.id)
    const { items, loading } = useGetReviews(state.page, state.pageSize, selectedSubject?.id)

    const rows: GridRowsProp = items?.map(value => {
        return {
            ...value
        }
    }) ?? []

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [value, setValue] = React.useState('');
    
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        const field = event.currentTarget.dataset.field!;
        if(field !== 'source' && field !== 'langCode')
            return
        
        const row = Number(event.currentTarget.parentElement!.dataset!.rowindex);
        const value = (items as Review[])[row][field];
        
        if(value === undefined)
            return;
        
        setValue(value.toString());
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    
    const [ selectedReview, setSelectedReview ] = useState<string>()

    const onCloseReplySentAlert = useCallback(() => setReplySentAlert(false), [])
    
    const onCloseReplyDialog = useCallback(() => setSelectedReview(undefined), [])
    
    const onSuccessSendReply = useCallback(() => {
        setSelectedReview(undefined)
        setReplySentAlert(true)
        apolloClient.refetchQueries({
            include: [ "getReviews" ]
        })
    }, [])
    
    useEffect(() => {
        onReplyClick.watch(review => {
           setSelectedReview(review.id)
        });
    }, [])
    
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarExport />
                <Box sx={{ ml: 'auto', mr: 1 }}>
                    <FormControlLabel control={<Switch checked={compact} onChange={e => setCompact(e.target.checked)} />} label="Compact" sx={{ userSelect: 'none' }} labelPlacement='start' />
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
            <DataGrid rows={rows} columns={columns}
                      disableColumnMenu
                      density={compact ? 'compact' : 'standard'}
                      loading={loading}
                      getRowHeight={() => 'auto' }
                      disableSelectionOnClick
                      initialState={{ columns: { 
                          columnVisibilityModel: { 
                              id: false,
                              status: false,
                              reply: false
                          } } }}
                      componentsProps={{
                          cell: {
                              onMouseEnter: handlePopoverOpen,
                              onMouseLeave: handlePopoverClose,
                          },
                      }}
                      sx={{
                          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                          height: '100%'
                      }}
                      components={{
                          Toolbar: CustomToolbar,
                          LoadingOverlay: LinearProgress,
                          NoRowsOverlay: CustomNoRowsOverlay,
                      }}/>
                <Popover
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography sx={{ p: 1 }}>{value}</Typography>
                </Popover>
            </div>
            <ReviewDialog reviewID={selectedReview} 
                          isOpen={selectedReview !== undefined} 
                          onClose={onCloseReplyDialog} 
                          onSuccess={onSuccessSendReply} />
            <Snackbar open={replySentAlert}
                      autoHideDuration={6000}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      onClose={onCloseReplySentAlert}>
                <Alert onClose={onCloseReplySentAlert}
                       severity="success"
                       sx={{ width: '100%' }}>
                    Feedback has been successfully sent!
                </Alert>
            </Snackbar>
        </div>
    )
}