import React, {useEffect, useState} from 'react';

import { useNavigate, useLocation } from "react-router-dom";

import { useSignIn, useIsAuthenticated } from "react-auth-kit";
import { useFormik } from "formik";

import {Container, Box, Typography, Button, TextField, Link, Backdrop, CircularProgress, Alert} from '@mui/material';
import {AuthError, useAuthRequest, useRefresh} from "../Requests/LoginRequests";

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        TipZ.Analytics
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const validRedirects = [
    '/dashboard',
    '/reviewReply'
]

function getRedirectUri(path: string)
{
  for (const index in validRedirects) 
  {
    if(path.startsWith(validRedirects[index]))
      return path;
  }

  return '/dashboard'
}

function Login(props: any) {
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate();
  const location = useLocation();
  
  const redirectTo = getRedirectUri(location.state?.from?.pathname ?? '/dashboard')

  const [ refreshMutation ] = useRefresh();
  const [ authMutation ] = useAuthRequest()
  const [ error, setError ] = useState<AuthError>()

  useEffect(() => {
    if(isAuthenticated())
      navigate(redirectTo)
    else {
      const access = localStorage.getItem("token");
      const refresh = localStorage.getItem("token_refresh");
      
      if(access && refresh)
      {
        refreshMutation({
          variables: {
            accessToken: access,
            refreshToken: refresh,
          }
        }).then(value => {

          const response = value.data?.refresh;
          if(response?.success)
          {
            signIn({
              token: response.accessToken!,
              refreshToken: refresh,
              refreshTokenExpireIn: 129600,
              expiresIn: response.expiresIn,
              tokenType: "Bearer",
              authState: {  }
            })
            setTimeout(() => {
              navigate(redirectTo)
            }, 100);
          }
        })
      }
    }
  }, [])
  
  const signIn = useSignIn();

  const onSubmit = async (values: any) => {

    try {

      const response = await authMutation({
        variables: values
      })

      setError(response.data?.auth?.error);

      if (!response.data?.auth.success)
            return

      if(signIn({
        token: response.data.auth.accessToken!,
        refreshToken: response.data.auth.refreshToken,
        refreshTokenExpireIn: 129600,
        expiresIn: response.data.auth.expiresIn,
        tokenType: "Bearer",
        authState: {  },
      }))
      {
        setTimeout(() => {
          navigate(redirectTo)
        }, 100);
      }
    }
    catch (err)
    {
      console.log("Error: ", err);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit,
  });
const hasError = error?.message !== undefined;
const errorDisplay = hasError ? 'flex' : 'none';

return (
  <Container maxWidth="xs">
      <Box sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={error?.target.isLogin}
            autoFocus
          />
          <TextField
              margin="normal"
              name="password"
              label="Password"
              id="password"
              required
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={error?.target.isPassword}
              placeholder="Password"
              type="password"
              autoComplete="current-password"
            />
          <Alert sx={{ display: errorDisplay }} severity="error">{error?.message}</Alert>
          <Button type="submit"
              fullWidth
              variant="contained"
              disabled={formik.isSubmitting}
              sx={{ mt: 3, mb: 2 }}
            >
            Login
          </Button>
        </Box>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={formik.isSubmitting}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      
      <Copyright sx={{ mt: 8, mb: 4 }} />
  </Container>
)
}

export { Login };