import {gql, useMutation, useQuery} from "@apollo/client";
import {LocationSource} from "./LocationInfoRequests";
import { LogRequest, LogRequestResult} from "../Logger";
import {graphQL_Client} from "../GraphQLRoot";


const GET_REVIEWS_COUNT = gql`
    query getReviewsCount($subjectId: String!)
    {
        reviews(subjectId: $subjectId) {
            totalCount
        }
    }`;

const GET_REVIEWS = gql`
    query getReviews($subjectId: String!, $skip: Int!, $take: Int!)
    {
        reviews(skip: $skip, take: $take, subjectId: $subjectId, where: {}) {
            items {
                id
                created
                status
                source
                rating
                author
                text
                reply
                langCode
            }
        }
    }
`

const GET_AI_REVIEW_REPLY = gql`
    mutation getReviewReply($reviewID: String!)
    {
        reviewReply(reviewId: $reviewID) {
            success
            reply
        }
    }
`

const SEND_REVIEW = gql`
    mutation sendReview($subjectID: String!, $reviewID: String!, $reply: String!)
    {
        sendReview(subjectID: $subjectID, id: $reviewID, reply: $reply) {
            success
        }
    }
`

const GET_REVIEW_BY_ID = gql`
    query GetReviews($reviewID: String!)
    {
        review(reviewID: $reviewID)
        {
            id
            rating
            author
            authorUrl
            langCode
            source
            text
            subject
            reply
        }
    }
`

export enum Status {
    NONE,
    SKIP,
    SENDED
}

export interface Review {
    id: string,
    created: number,
    status: Status,
    source: LocationSource,
    subject?: string,
    rating: number,
    author: string,
    authorUrl?: string,
    text: string,
    reply?: string,
    langCode?: string
}

export const GetAiReply = (reviewID: string) =>
{
    return graphQL_Client.mutate<{reviewReply?: { success: boolean, reply?: string }}>({
        mutation: GET_AI_REVIEW_REPLY,
        variables: {
            reviewID: reviewID
        },
        context: {
            headers: {
                "authorization": localStorage.getItem('token')
            },
        },
        ...LogRequestResult('getAiReviewReply')
    });
}

export const SendReply = (subjectID: string, reviewID: string, reply: string) =>
{
    return graphQL_Client.mutate<{sendReview?: { success: boolean }}>({
        mutation: SEND_REVIEW,
        variables: {
            subjectID: subjectID,
            reviewID: reviewID,
            reply: reply
        },
        context: {
            headers: {
                "authorization": localStorage.getItem('token')
            },
        },
        ...LogRequestResult('sendReviewReply')
    });
}

export const useGetReviewsCount = (subjectId?: string) => {
    const query = useQuery<{reviews?: { totalCount: number }}>(GET_REVIEWS_COUNT, {
        context: {
            headers: {
                "authorization": localStorage.getItem('token')
            }
        },
        variables: {
            subjectId: subjectId,
        },
        skip: !subjectId
    })

    LogRequest('getReviewsCount', query)
    
    return query.data?.reviews?.totalCount;
};

export const useGetReviews = (page: number, pageSize: number, subjectId?: string) => {
    const request = useQuery<{reviews?: { items: Review[] }}>(GET_REVIEWS, {
        context: {
            headers: {
                "authorization": localStorage.getItem('token')
            }
        },
        variables: {
            subjectId: subjectId,
            skip: page * pageSize,
            take: (page+1) * pageSize
        },
        skip: !subjectId
    })

    LogRequest('getReviews', request)
    
    return { 
        items: request.data?.reviews?.items,
        loading: request.loading 
    }
};

export const useGetReview = (reviewID?: string) => {
    const request = useQuery<{review?: Review}>(GET_REVIEW_BY_ID, {
        context: {
            headers: {
                "authorization": localStorage.getItem('token')
            }
        },
        variables: {
            reviewID: reviewID ?? ''
        },
        skip: reviewID === undefined
    })
    
    LogRequest('getReview', request)
    
    return {
        'data': request?.data?.review, 
        'isLoading': request.loading
    }
}