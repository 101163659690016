import { gql, useMutation, useQuery } from "@apollo/client";
import { LocationInfo } from "../Types/DashboardTypes";
import { LogRequest, LogRequestResult} from "../Logger";

export const GET_LOCATION_INFO = gql`
    query getLocationInfo($subjectId: String!, $source: LocationSource!){
        locationInfo(subjectId: $subjectId, source: $source) {
            isEnable
            isVerified
            location
            name
            notifyIf {
                isEnable
                crone
                scoreLess
            }
        }
    }
`;

const UPDATE_LOCATION_INFO = gql`
    mutation updateLocationInfo($subjectId: String!, $input: UpdateLocationInfoInput!) {
        updateLocationsInfo(subjectId: $subjectId, input: $input) {
            isSuccess,
            updateQuery
        }
    }
`;

export enum LocationSource {
    GOOGLE_MAP,
    TRIPADVISOR
}

export const useGetLocationInfo = (source: LocationSource, subjectId?: string) => {
    const query = useQuery<{locationInfo?: LocationInfo}>(GET_LOCATION_INFO, {
        context: {
            headers: {
                "authorization": localStorage.getItem('token')
            }
        },
        variables: {
            subjectId: subjectId,
            source: LocationSource[source]
        },
        skip: !subjectId
    })

    LogRequest('getLocationInfo', query)
    
    return query;
};

export interface UpdateLocationInfoInput {
    googleMap?: LocationInfo
}

interface UpdateLocationInfoResponse {
    isSuccess: boolean,
    updateQuery: LocationSource[]
}

export const useUpdateLocationInfo = () => {
    return useMutation<{updateLocationsInfo: UpdateLocationInfoResponse}>(UPDATE_LOCATION_INFO, {
        context: {
            headers: {
                "authorization": localStorage.getItem('token')
            }
        },
        ...LogRequestResult('updateLocationInfo')
    })
};