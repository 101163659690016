import { Box, Typography } from '@mui/material';
import * as React from 'react';

const MainContent = () => {

    return (<Box>
<Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
          </Typography>
    </Box>)
}

export { MainContent }