import {ApolloError, QueryResult, ServerError} from "@apollo/client";
import {ServerParseError} from "@apollo/client/link/http";
import axios from "axios";
import {useEffect, useState} from "react";
import {createEvent, createStore} from "effector";

interface LogRequest {
    ts: string
    request: string,
    location: string,
    level: string,
    code: number
}

const logStore = createStore<LogRequest[]>([], {
    name: 'logStore'
});
const pushLog = createEvent<LogRequest>();
const flushLogs = createEvent();

logStore.on(pushLog, (state, payload) => {
    if(state.find(value => value.request == payload.request))
        return;

    if(state.length == 0)
    {
        setTimeout(() => {
            flushLogs()
        }, 2000);
    }
    return [...state, payload];
})

logStore.on(flushLogs, (state) => {
    const request = state.map(value => [
        value.ts, JSON.stringify({
            code: value.code,
            request: value.request,
            location: value.location,
            level: value.level
        })
    ])
    
    axios.post('/logs', {
        streams: [
            {
                "stream": {
                    "app": "frontend"
                },
                "values": request
            }
        ]
    });
    
    return []
})

function SendLog(request: string, statusCode: number) {
    const timestamp = new Date()
    
    pushLog({
        code: statusCode,
        location: location.pathname,
        level: 'info',
        request: request,
        ts: String(timestamp.getTime() * 1000000)
    })
}

function LogRequestError(request: string, error: ApolloError)
{
    if(error.networkError === null)
        return
    
    const serverError = error.networkError as ServerParseError | ServerError;
    if(serverError)
        SendLog('auth', serverError.response.status)
}

export function LogRequestResult(request: string)
{
    return {
        onError: (error: ApolloError) => {
            LogRequestError(request, error);
        },
        onCompleted: () => SendLog(request, 200)
    }
}

export async function LogRequest(requestName: string, request: QueryResult)
{
    if(!request.loading)
        return
    await request;
    
    if(request.error)
        LogRequestError(requestName, request.error);
    else
        SendLog(requestName, 200)
}