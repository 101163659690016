import {Box, CircularProgress, Fab, Zoom} from "@mui/material";
import {green, red} from "@mui/material/colors";
import * as React from "react";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import {useGetActiveSubject} from "../../../Requests/DashbordRequests";

export enum ContentStatus
{
    Changed = 1,
    Saving = 2,
    Error = 3,
    Saved = 4,
}

export const getStyleByState = (status: ContentStatus) => {
    return status == ContentStatus.Saving ?
        {
            filter: 'blur(3px)',
            'pointer-events':'none'
        } :
        {

        }
}

type Props = {
    canSubmit?: boolean
    status: ContentStatus,
    onSubmit: () => Promise<any>,
    children: React.ReactNode
};

export const ChangingBox: React.FC<Props> = ({ status, onSubmit, children, canSubmit }) => {
    
    const buttonSx = {
        ...((status == ContentStatus.Saved && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }) || (status == ContentStatus.Error &&
            {
                bgcolor: red[500],
                '&:hover': {
                    bgcolor: red[700],
                },
            }))
    };

    let icon = <SaveIcon />;
    switch (status)
    {
        case ContentStatus.Saved:
            icon = <CheckIcon/>
            break
        case ContentStatus.Error:
            icon = <ErrorIcon/>
            break
    }

    const subject = useGetActiveSubject();
    
    return (
        <Box display='flex'>
            <Box style={getStyleByState(status)} sx={{
                width: '100%'
            }}>
                {children}
            </Box>
            <Zoom in={true}>
                <Box position='fixed'
                     hidden={subject.loading || subject.data === undefined}
                     right='0'
                     bottom='0'
                     sx={{mr: 3, mb: 4}}
                     z-index='98'>
                    <Fab
                        aria-label="save"
                        color="primary"
                        sx={buttonSx}
                        disabled={canSubmit === false}
                        onClick={() => {
                            if(status == ContentStatus.Saving || status == ContentStatus.Saved)
                                return
                            onSubmit().then(value => true)
                        }} >
                        {icon}
                    </Fab>
                    {status == ContentStatus.Saving && (
                        <CircularProgress
                            size={68}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: -6,
                                left: -6,
                                zIndex: 99,
                            }}
                        />
                    )}
                </Box>
            </Zoom>
        </Box>
    )
}