import React from 'react';

import { Login } from "./Pages/Login";
import { RequireAuth } from "react-auth-kit";
import { useRoutes } from "react-router-dom";

import { Dashboard } from "./Pages/Dashboard";

import { TakeRoute } from './Pages/Dashboard/Menu'
import {Chat} from "./Pages/Chat";
import {ClosePage} from "./Pages/ClosePage";
import {SentReviewReplyPage} from "./Pages/SentReviewReplyPage";

function App() {
  const routes = useRoutes([
    {
      path:'/dashboard',
      element: <RequireAuth loginPath='/'><Dashboard /></RequireAuth>,
      children: TakeRoute()
    },
    {
      path:"/reviewReply/:id",
      element: <RequireAuth loginPath='/'><SentReviewReplyPage /></RequireAuth>,
    },
    {
      path:"/",
      element: <Login />
    },
    {
      path:"/chat",
      element: <Chat />
    },
  ]);
  
    return routes
}

export default App;
