import { gql, useMutation, useQuery } from "@apollo/client";
import { Subject, User} from "../Types/DashboardTypes";
import {useStore} from "effector-react";
import {$selectedSubject} from "../Pages/Dashboard";
import {LogRequest, LogRequestResult} from "../Logger";



const GET_ALL_SUBJECTS = gql`
    query getSubjects {
        user {
            id
            email,
            flags {
                isSuperuser
            }
        },
        subjects {
            id
            title
        }
    }
`;

const GET_SUBJECT_BY_ID = gql`
    query getSubjectByID($id: String!) {
        subjectById(id: $id) {
            id
            title,
            notificationsSettings {
                sendToPhone
                phoneNumber
            }
        }
    }
`;

const UPDATE_SUBJECT = gql`
    mutation updateSubjectInfo ($subjectId: String!, $input: UpdateSubjectInfoInput!) {
      updateSubjectInfo(subjectId: $subjectId, input: $input)
    }
`;

export interface UserInfo
{
    user: User,
    subjects: Subject[]
}

export const useGetUserInfo = () => {
    const query = useQuery<UserInfo>(GET_ALL_SUBJECTS, {
        context: {
            headers: {
                "authorization": localStorage.getItem('token')
            }
        }
    })

    LogRequest('getUserInfo', query)
    
    return query;
};

export const useGetSubject = (subjectID?: string, logMessage='getSubject') => {

    const query = useQuery<{subjectById: Subject}>(GET_SUBJECT_BY_ID, {
        context: {
            headers: {
                "authorization": localStorage.getItem('token')
            }
        },
        variables: {
            id: subjectID ?? ''
        },
        skip: !subjectID,
    })

    LogRequest(logMessage, query)

    return query;
};


export const useGetActiveSubject = () => {
    const selectedSubject = useStore($selectedSubject);
    return useGetSubject(selectedSubject?.id, 'getActiveSubject');
};


export const useUpdateSubject = () => {
    const selectedSubject = useStore($selectedSubject);
    
    return useMutation<boolean>(UPDATE_SUBJECT, {
        context: {
            headers: {
                "authorization": localStorage.getItem('token')
            }
        },
        refetchQueries: [
            { query: GET_SUBJECT_BY_ID, variables: { id: selectedSubject?.id ?? '' } }
        ],
        ...LogRequestResult('updateSubject')
    });
}