import { Grid } from "@mui/material";
import React from "react";
import { ChatText } from "./ChatText";
import { Transmission } from "./Transmission";

export function ChatBubble({
                               transmission,
                               children,
                           }: {
    transmission: Transmission;
    children: React.ReactNode;
}) {
    return (
        <Grid minWidth={150} maxWidth='65%' item>
            <ChatText transmission={transmission}>{children}</ChatText>
        </Grid>
    );
}