import {BootstrapDialog, BootstrapDialogTitle} from "./BootstrapDialog";
import {
    Alert,
    Avatar, Backdrop,
    Box,
    Button, CardContent, CircularProgress, DialogActions,
    DialogContent,
    Divider, FormControl, InputLabel, Rating, Snackbar, Stack, TextField, Typography, Zoom,
} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {GetAiReply, SendReply, useGetReview} from "../Requests/ReviewRequests";
import {useGetSubject} from "../Requests/DashbordRequests";

import { Card, CardHeader  } from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import SendIcon from '@mui/icons-material/Send';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import {LocationSource, useGetLocationInfo} from "../Requests/LocationInfoRequests";

enum DialogState {
    Writing,
    Complete,
    Error
}

export const ReviewDialog = (props: { reviewID?: string, isOpen: boolean, onClose: () => void, onSuccess?: () => void }) => {
    const review = useGetReview(props.reviewID);
    const subject = useGetSubject(review.data?.subject)
    const locationInfo = useGetLocationInfo(LocationSource.GOOGLE_MAP, subject.data?.subjectById?.id);
    
    const [ state, setState ] = useState<DialogState>(DialogState.Writing)
    const isLoadingNext = review.isLoading || locationInfo.loading || subject.loading || subject.data === undefined;
    
    const [ isCompleteLoading, setCompleteLoading ] = useState<boolean>(false)
    
    useEffect(() => {
        if(isLoadingNext === false)
            setCompleteLoading(!isLoadingNext)
        else if(isLoadingNext === true && (review.data?.reply?.length ?? 0) > 3)
        {
            setReply(review.data?.reply!)
            setState(DialogState.Complete)
            if(props.onSuccess)
                props.onSuccess();
        }
    }, [isLoadingNext, review.data])
    
    const [ isAiReplyOpen, setAiReplyOpen ] = useState<boolean>(false)

    const [ isProgress, setProgress ] = useState<boolean>(false)
    
    const [ title, setTitle ] = useState<string>('Loading...')
    const [ reply, setReply ] = useState<string>('')
    
    useEffect(() => {
        if(props.isOpen)
        {
            setState(DialogState.Writing);
            setReply('')
        }
    }, [props.isOpen])
    
    useEffect(() => {
        const nextTitle = locationInfo?.data?.locationInfo?.name
        if(nextTitle !== undefined)
            setTitle(nextTitle);
        
    }, [locationInfo?.data])
    
    const handleAiReplyClose = useCallback(() => {
        setAiReplyOpen(false)
    }, [])

    const handleSendReply = useCallback(async () => {
        const reviewID = review.data!.id;
        const subject = review.data!.subject!;

        setProgress(true)
        SendReply(subject, reviewID, reply!).then(response => {
            const result = response.data?.sendReview?.success ? DialogState.Complete : DialogState.Error;
            return Promise.resolve(result)
        }, () => Promise.resolve(DialogState.Error))
            .then(value => {
                setState(value)
                setProgress(false)
                setTimeout(() => {
                    if(props.onSuccess)
                        props.onSuccess();
                }, 2000);
            })
    }, [review.data, reply, props.onSuccess])
    
    const onAiReplyClicked = useCallback(async () => {
        const reviewID = review.data?.id;
        
        setProgress(true)
        try {
            const response = await GetAiReply(reviewID!)

            if(response.data?.reviewReply?.reply)
                setReply(response.data?.reviewReply?.reply);
            else
                setAiReplyOpen(true)
        }
        finally {
            setProgress(false)
        }
    }, [review.data])
    
    const ContentByState = useMemo(() => {
        if(!isCompleteLoading)
            return (<Box display='flex' flex='1' alignItems='center' justifyContent='center'>
                <CircularProgress />
            </Box>)
        
        switch (state)
        {
            case DialogState.Writing:
                return (<Stack direction='column' flex='1' spacing={2}>
                    <Card>
                        <CardHeader avatar={<Avatar aria-label="recipe" 
                                                    src={review.data?.authorUrl} />} 
                                    title={review.data?.author ?? 'Anonymous'}
                                    subheader={<Rating readOnly={true} value={review.data?.rating} /> }
                        />
                        <CardContent sx={{ display: review.data?.text ? '' : 'none' }}>
                            {review.data?.text}
                        </CardContent>
                    </Card>
                    
                    <Box display='flex' flexGrow='1' justifyContent='flex-end'>
                        <Button variant='outlined' 
                                size='small' 
                                onClick={onAiReplyClicked}
                                endIcon={<TipsAndUpdatesIcon />}>Ai reply</Button>
                    </Box>
                    <TextField label='Reply'
                               InputLabelProps={{ shrink: true }}
                               rows={10}
                               fullWidth multiline
                               value={reply}
                               placeholder='Enter reply...'
                               onChange={e => setReply(e.target.value)} />
                </Stack>)
            case DialogState.Error:
                return (<Zoom in={true}>
                    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' flex='1'>
                        <WarningIcon sx={{ fontSize: 100, mb: 2 }} color="error" />
                        <Typography align='center'>Sending feedback failed... <br/>
                            Please try again...</Typography>
                    </Box>
                </Zoom>)
            case DialogState.Complete:
                return (<Zoom in={true}>
                    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' flex='1'>
                        <CheckCircleIcon sx={{ fontSize: 100, mb: 2 }} color="success" />
                        <Typography align='center'>Success!</Typography>
                    </Box>
                </Zoom>)
            default: 
                return (<></>)
        }
    }, [state, isCompleteLoading, reply])
    
    return (
            <BootstrapDialog sx={{ zIndex: 2000 }} fullWidth={true} maxWidth={"sm"} disableEscapeKeyDown open={props.isOpen} >
                <BootstrapDialogTitle onClose={props.onClose}>{title ?? 'Loading...'}</BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box flexDirection='column' display='flex' width='100%' height='100%' minHeight={300} >
                        {ContentByState}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' 
                            sx={{ display: state === DialogState.Complete ? 'none': undefined }}
                            disabled={(reply?.length ?? 0) < 3} 
                            onClick={handleSendReply}
                            endIcon={<SendIcon />} >{state === DialogState.Writing ? 'send' : 're send'}</Button>
                </DialogActions>
                
                <Backdrop open={isProgress} sx={{ zIndex: 3000 }} >
                    <CircularProgress />
                </Backdrop>
                
                <Snackbar open={isAiReplyOpen} 
                          autoHideDuration={6000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          onClose={handleAiReplyClose}>
                    <Alert onClose={handleAiReplyClose} 
                           severity="error" 
                           sx={{ width: '100%' }}>
                        Ai response request failed!
                    </Alert>
                </Snackbar>
            </BootstrapDialog>
    );
}