import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    AccordionDetails, Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    DialogContent,
    Divider,
    FormControl, FormControlLabel,
    Input,
    InputAdornment,
    InputLabel, List, ListItemButton, ListItemText, Rating,
    Stack,
    Switch,
    TextField,
    Typography,
    Stepper,
    Step,
    StepLabel, Alert, Theme, ListItem, ListItemIcon
} from "@mui/material";

import {LocationOn} from "@mui/icons-material";
import {BootstrapDialog, BootstrapDialogTitle} from "../../../Components/BootstrapDialog";
import {useStore} from "effector-react";
import { contentStatusStore } from "../IntegrationContent";
import {createEvent, createStore} from 'effector';
import {ContentStatus} from "../Base/ChangingBox";
import {SxProps} from "@mui/system";

import {Cron} from "react-js-cron";
import {LocationInfo, NotifyIf} from "../../../Types/DashboardTypes";
import {$selectedSubject} from "../../Dashboard";
import {LocationSource, useGetLocationInfo} from "../../../Requests/LocationInfoRequests";

import {grey} from '@mui/material/colors'

import { useApolloClient } from "@apollo/client";


import 'react-js-cron/dist/styles.css'
import "./GooglePlaceCard.css";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {useGetUserInfo} from "../../../Requests/DashbordRequests";
import {onSavedEvent} from "../../../Events/DashboardEvents";
import axios from "axios";

import useLoadGsiScript, {
} from './google/useLoadGsiScript';
import {CodeResponse, NonOAuthError, TokenResponse } from "./google/types";
import {Accordion, AccordionSummary} from "../../../Components/Accordion";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StoreIcon from '@mui/icons-material/Store';

const resetStore = createEvent<LocationInfo>()
const updateStore = createEvent<LocationInfo>()

const changeIsEnableHandler = createEvent<boolean>()
const notifyIfUpdate = createEvent<NotifyIf>()

const updateScoreHandler = (value: number | null) => {
    if(!value)
        return
    
    notifyIfUpdate({
        scoreLess: Math.min(Math.max(value, 0.5), 5)
    })
}

const updateNotificationEnableHandler = (value: boolean) => {
    if(!value)
        return

    notifyIfUpdate({
        isEnable: value
    })
}


const updateResetNextCollectionTimeHandler = (value: boolean) => {

    // @ts-ignore
    updateStore({
        resetNextCollectionTime: value
    })
}

const updateResetLastCollectionTimeHandler = (value: boolean | number | null) => {
    if(value == null)
        return
    
    let number: number | undefined = undefined;
    
    if(value)
    {
        switch (typeof value)
        {
            case 'boolean':
                number = Date.now();
                break
            case 'number':
                number = value as number;
                break
            default:
                number = value;
                break
        }
    }

    // @ts-ignore
    updateStore({
        resetLastCollectionTime: number
    })
}

export const googlePlaceStore = createStore<LocationInfo>({
    isEnable: false,
    notifyIf: {
        isEnable: false,
        scoreLess: 3
    }
}, { name: 'google_maps' })


googlePlaceStore.on(resetStore,  (state, payload) => payload);

googlePlaceStore.on(changeIsEnableHandler, (lastState, value) => {
    return {
        ...lastState,
        isEnable: value
    }
})

googlePlaceStore.on(notifyIfUpdate, (lastState, value) => {
    return {
        ...lastState,
        ...{
            notifyIf: {
                ...lastState.notifyIf,
                ...value
            }
        }
    }
})

googlePlaceStore.on(updateStore, (state, payload) => {
    return {
        ...state,
        ...payload
    }
})

// @ts-ignore
googlePlaceStore.on(onSavedEvent, (state, payload) => {
    return {
        ...state,
        resetLastCollectionTime: undefined,
        resetNextCollectionTime: undefined
    }
})

const toggleAuth = createEvent<boolean>('toggleAuth')

enum States {
    None = -1,
    Auth,
    SelectAccount,
    SelectPlace
}

const DontVerifiedAlertBox = (props: { showIf?: boolean }) => {
    return (<Alert severity='warning' sx={{ display: (props.showIf ?? false) ? 'flex' : 'none', alignItems: 'center', paddingY: 0 }}>
        <Box  sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
                sx={{ display: 'inline', fontWeight: 'bold' }}
                component="span"
                variant="body2"
                color="text.primary"
            >Account not verified!</Typography>
            <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="caption"
                color="text.primary"
            >Receiving and replying to reviews is not available.</Typography>
        </Box>
    </Alert>)
}

const AuthDialog = () => {
    const selectedSubject = useStore($selectedSubject)
    
    const steps = [ 'Google auth', 'Select account', 'Select place' ];
    const actions = [ undefined, 'select_account', 'select_place' ];

    const googleClient = useRef<any>()
    const requestHeaders = useRef<any>()
    const [hasError, setHasError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState<States>(States.None);
    
    const [selectedIndex, setSelectedIndex] = React.useState<string | undefined>(undefined);
    const [ list, setList ] = React.useState<any[] | undefined>(undefined)
    
    const [ accounts, setAccounts ] = React.useState<any[]>();

    useEffect(() => {
        if(!selectedSubject)
            return

        requestHeaders.current = {
            'Authorization': localStorage.getItem('token'),
            'SubjectID': selectedSubject.id
        }
    }, [ selectedSubject ])
    
    const cancelAuth = () => {
        setLoading(false)
        setActiveStep(States.None)
        setSelectedIndex(undefined)
    }
    
    const completeAuth = () => {
        setLoading(false)
        setActiveStep(States.None)
        setSelectedIndex(undefined)
    }
    
    const completeLoading = (values: any[], nextStep?: States ) => {
        setList(values);
        setSelectedIndex(undefined)
        if(nextStep)
            setActiveStep(nextStep)
        setLoading(false)
    }

    const ListItemByStep = (props: { value: any }) => {
        
        const IconPart = useMemo(() => {
                switch(activeStep)
                {
                    case States.SelectAccount:
                        return (<AccountCircleIcon />)
                    case States.SelectPlace:
                        return (<StoreIcon />)
                    default:
                        return (<></>)
                }
            }, 
            [activeStep])
        
        const TextPart = (props: {value: any}) => {
            switch(activeStep)
            {
                case States.SelectAccount:
                    return (<ListItemText primary={props.value.accountName} secondary={ 'ID: '+props.value.name }  />)
                case States.SelectPlace:
                    const title = props.value.title
                    const address = props.value.address
                    return (<ListItemText primary={title} secondary={address} />)
                default:
                    return (<></>)
            }
        }
        
        return (<ListItem disablePadding sx={{ flexDirection: 'column', alignItems: 'stretch'}}>
            <ListItem sx={{paddingY: 0}}>
                <ListItemIcon>
                    {IconPart}
                </ListItemIcon>
                <TextPart value={props.value}/>
            </ListItem>
            <DontVerifiedAlertBox showIf={props.value?.isVerified === false} />
        </ListItem>)
    }
    
    const apolloClient = useApolloClient();
    const getIdByStep = useCallback((value: any) =>
    {
        switch (activeStep)
        {
            case States.SelectAccount:
                return value.name;
            case States.SelectPlace:
                return value.id;
            default:
                return 0;
        }
    }, [activeStep])
    
    const setError = () => {
        setLoading(false)
        setHasError(true)
    }
    
    const backPopupHandle = useCallback(() => {
        switch (activeStep)
        {
            case States.SelectPlace:
                completeLoading(accounts!, States.SelectAccount)
                break
        }
    }, [activeStep])
    
    const submitPopup = useCallback(async ( step?: States ) => {
        const selected = selectedIndex
        const lastState = step ?? activeStep;

        if(step !== undefined)
            setActiveStep(step)

        setHasError(false)
        setList(undefined)
        setLoading(true)

        if(lastState < States.SelectAccount)
            googleClient.current?.requestCode();
        else
        {
            const action = actions[lastState]
            
            try {
                const request = await axios.post(`/api/google_api/${action}/${selected}`, undefined, {
                    headers: requestHeaders.current
                })

                if(request.status == 200)
                {
                    switch (lastState)
                    {
                        case States.SelectAccount:
                            const places = request.data.places
                            if(request.data.success && places?.length > 0)
                            {
                                completeLoading(request.data.places, States.SelectPlace)
                                return
                            }
                            break
                        case States.SelectPlace:
                            if(request.data.success)
                            {
                                apolloClient.refetchQueries({
                                    include: [ "getLocationInfo" ]
                                })
                                completeAuth()
                                return
                            }
                            break
                    }
                }

                setError()
            }
            catch
            {
                setError()
            }
        }
    }, [selectedIndex, activeStep, googleClient, apolloClient])
    
    const logoutCallback = useCallback(() => {
        setLoading(true)
        
        return axios.post('/api/google_api/logout', undefined, {
            headers: requestHeaders.current
        }).then(request => {
            if(request.data.success)
                return apolloClient.refetchQueries({
                    include: [ "getLocationInfo" ]
                }) as Promise<any>
            return Promise.resolve()
        }).finally(() =>
            cancelAuth()
        )
    }, [])

    const toggleAuthCallback = useCallback((isLogin: boolean) => {
        return isLogin ? submitPopup(States.Auth) : logoutCallback();
    }, [submitPopup])
    
    const googleErrorCallback = useCallback((nonOAuthError: NonOAuthError) => {
        if(nonOAuthError.type !== "unknown")
        {
            if(activeStep < States.SelectAccount)
                cancelAuth()
        }
        else
            setError()
    }, [activeStep])
    
    const googleCallback = useCallback((input: CodeResponse) => {
        return Promise.resolve().then( async () => {
            if (input.error)
                return Promise.reject();
            
            setActiveStep(States.SelectAccount)

            const bodyFormData = new FormData();
            bodyFormData.append('code', input.code);

            const response = await axios.post(`/api/google_api/sign_in`, bodyFormData, {
                headers: requestHeaders.current
            })

            const accounts = response.data.accounts

            if(response.data.success && accounts?.length > 0)
            {
                setAccounts(accounts)
                
                completeLoading(accounts, States.SelectAccount)
                return Promise.resolve();
            }
            
            return Promise.reject();
        }).catch(() => {
            setActiveStep(States.Auth)
            setError()
        })
    }, [ ])
    
    useEffect(() => {
        const clientId = '760549664269-rvnp1jh8qa2ji67ia3erruakstmp148i.apps.googleusercontent.com'

        // @ts-ignore
        const client = window.google?.accounts.oauth2['initCodeClient']({
            client_id: clientId,
            access_type: 'offline',
            scope: [
                'profile',
                'email',
                'https://www.googleapis.com/auth/plus.business.manage',
                'https://www.googleapis.com/auth/business.manage',

            ].join(' '),
            callback: googleCallback,
            error_callback: googleErrorCallback,
        });

        googleClient.current = client
        toggleAuth.watch(toggleAuthCallback)
    }, [ ]);
    
    const isOpen = activeStep != States.None;
    
    return (<React.Fragment>
        <BootstrapDialog sx={{ zIndex: 2000 }} fullWidth={true} maxWidth={"sm"} disableEscapeKeyDown open={isOpen} >
        <BootstrapDialogTitle onClose={cancelAuth}>{steps[activeStep]}</BootstrapDialogTitle>
        <DialogContent dividers>
            <Box flexDirection='column' display={'flex'} width={'100%'} height={'100%'} minHeight={400}>
                <Stepper sx={{ pb: 1 }} activeStep={activeStep ?? 0} alternativeLabel>
                    {steps.map((value, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};

                        return (
                            <Step key={index} {...stepProps}>
                                <StepLabel {...labelProps}>{value}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
                <Divider sx={{ mb: 1 }} />

                { activeStep == States.Auth || hasError ? 
                    <Box display='flex' flex='1' /> :
                    <List sx={{ width: '100%', flex: '1', bgcolor: 'background.paper' }}>
                        {list?.map(value => {
                            const key = getIdByStep(value);

                            return (
                                <ListItemButton selected={selectedIndex == key}
                                                key={key}
                                                onClick={() => setSelectedIndex(key)}
                                                alignItems="flex-start">
                                    <ListItemByStep value={value} />
                                </ListItemButton >)
                        })}
                    </List>
                }
                
                <Divider sx={{ mt: 1 }} />
                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', pt: 3 }}>
                    {hasError ?  
                        <Alert sx={{ mb: 1, flex: '1' }} severity="error" action={
                            <Button color="inherit" 
                                    size="small" 
                                    onClick={() => submitPopup()}>
                                ReTry
                            </Button>} >An unidentified error has occurred, please try again...</Alert> :
                        <Box flex='1' display='flex' justifyContent='flex-end'>
                            <Button hidden={true}
                                    sx={{ display: activeStep <= States.SelectAccount ? 'none' : '' }}
                                    onClick={backPopupHandle}
                                    variant="contained">Back</Button>
                            
                            <Button sx={{ 
                                        display: activeStep == States.Auth ? 'none' : '',
                                        marginLeft: 'auto'
                                    }}
                                    disabled={ selectedIndex === undefined }
                                    onClick={() => submitPopup()}
                                    variant="contained">{activeStep == steps.length - 1 ? "Done" : "Continue"}</Button>
                        </Box>
                    }
                </Box>

            </Box>
        </DialogContent>
    </BootstrapDialog>
    <Backdrop open={isLoading} sx={{ zIndex: 2001 }} >
        <CircularProgress />
    </Backdrop>
    </React.Fragment>)
}

export const GooglePlaceCard = (props : { sx?: SxProps<Theme> }) => {
    const userInfo = useGetUserInfo();

    const selectedSubject = useStore($selectedSubject)
    const scriptLoadedSuccessfully = useLoadGsiScript();
    
    contentStatusStore.on([changeIsEnableHandler, updateStore, notifyIfUpdate], () => ContentStatus.Changed);
    const locationInfo = useGetLocationInfo(LocationSource.GOOGLE_MAP, selectedSubject?.id);

    useEffect(() => {
        if(locationInfo.data?.locationInfo)
            resetStore(locationInfo.data.locationInfo);
    }, [ locationInfo.data ])
    
    const state = useStore(googlePlaceStore);
    const updateCronHandler = (crone: string) =>
    {
        const lastCrone = state.notifyIf?.crone;
        if(lastCrone && crone === lastCrone)
            return
        
        notifyIfUpdate({
            crone: crone
        })
    }
    
    const isEnable = state.isEnable;
    const resetNextCollectionTime = state.resetNextCollectionTime === true;
    
    return (
        <Card elevation={4} id="google-maps" sx={{ ...props.sx, minWidth: 275 }}>
            <CardContent>
                <Stack direction={"row"} alignItems={"center"} display={"flex"}>
                    <Switch size="medium" checked={isEnable} onChange={(e) => toggleAuth(e.target.checked)} />
                    <Typography sx={{ fontSize: 24, userSelect: 'none' }} color="text.secondary">
                        Google maps
                    </Typography>
                </Stack>
                <Divider sx={{ mt:1 }} />
                <Stack sx={{ mt: 2, display: 'flex', flexDirection: 'column' }} spacing={1} >
                    <FormControl fullWidth={true} disabled={!isEnable} variant="standard">
                        <InputLabel htmlFor="input-with-icon-adornment">
                            Subject address
                        </InputLabel>
                        <Input
                            id="input-with-icon-adornment"
                            startAdornment={
                                <InputAdornment position="start">
                                    <LocationOn />
                                </InputAdornment>
                            }
                            value={state.name ? (state.name + ' | ' + state.location) ?? '' : ''}
                            readOnly={true}
                        />
                    </FormControl>
                    <DontVerifiedAlertBox showIf={state?.isVerified === false && isEnable} />
                    <Box>
                        <Accordion sx={{ bgcolor: grey[700]  }}>
                            <AccordionSummary >
                                <Typography>Feedback collection settings</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{pb: 1}}>
                                <Box display='flex' flexWrap='wrap' alignItems='flex-end' justifyContent='space-between' columnGap={2}>
                                    <Cron disabled={!isEnable} clearButton={false}
                                          allowedPeriods={["hour", "minute", 'day']} clockFormat={"24-hour-clock"}
                                          value={state.notifyIf?.crone ?? '0 */6 * * *'} setValue={updateCronHandler}
                                          displayError={true}/>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ bgcolor: grey[700]  }}>
                            <AccordionSummary >
                                <Typography>Conditions for sending notifications</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{pb: 1}}>
                                <Box >
                                    <Typography  sx={{mb: -0.75}} component="legend">Send notifications?</Typography>
                                    <Switch value={state.notifyIf?.isEnable ?? false} onChange={event => updateNotificationEnableHandler(event.target.checked)} />
                                </Box>
                                <Box display='flex' flexWrap='wrap' alignItems='flex-end' justifyContent='space-between' columnGap={2}>
                                    <Box >
                                        <Typography component="legend">Review score less</Typography>
                                        <Rating value={state.notifyIf?.scoreLess ?? 3} disabled={!isEnable} onChange={(event, value) => updateScoreHandler(value)} precision={0.5} />
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion sx={{ bgcolor: grey[700]  }}>
                            <AccordionSummary >
                                <Typography>Update options</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{pb: 1}}>
                                <Box>
                                    <FormControlLabel disabled={!isEnable} control={<Switch checked={typeof state.resetLastCollectionTime === "number"} onChange={event => updateResetLastCollectionTimeHandler(event.target.checked)} />} label="Reset last collection time" />
                                    <Box hidden={!state.resetLastCollectionTime}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                disabled={!isEnable}
                                                value={state.resetLastCollectionTime ?? 0}
                                                onChange={value => updateResetLastCollectionTimeHandler(value)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                                <FormControlLabel hidden={userInfo.data?.user.flags?.isSuperuser !== true} disabled={!isEnable} control={<Switch checked={resetNextCollectionTime} onChange={event => updateResetNextCollectionTimeHandler(event.target.checked)} />} label="Reset next collection time" />
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Stack>
            </CardContent>
            { scriptLoadedSuccessfully ? <AuthDialog /> : <></> }
        </Card>
    )
}