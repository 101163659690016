
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Transmission } from "./Transmission";

export const ChatText = styled(Paper)<{ transmission: Transmission }>(
    ({ theme, transmission }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        color: theme.palette.text.secondary,
        borderRadius: "15px",
        borderTopLeftRadius: transmission === "receiver" ? "2px" : undefined,
        borderTopRightRadius: transmission === "sender" ? "2px" : undefined,
    })
);
  