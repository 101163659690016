import React, {useState} from "react";

import {
    Avatar,
    Backdrop,
    Button,
    CircularProgress,
    Divider, FormControl, IconButton, InputLabel, OutlinedInput,
    Paper, Stack,
    TextField,
    Typography,
    Unstable_Grid2 as Grid
} from '@mui/material';
import {Box} from "@mui/material";

import SendIcon from '@mui/icons-material/Send';
import {createEvent, createStore} from 'effector';
import {useStore} from "effector-react";
import {Transmission} from "../Components/Chat/Transmission";
import {SenderMessage} from "../Components/Chat/SenderMessage";
import {ReceiverMessage} from "../Components/Chat/ReceiverMessage";

import { blue, deepOrange } from '@mui/material/colors';
import {gql, useMutation} from "@apollo/client";
import { v4 as uuid } from 'uuid';
import {LogRequestResult} from "../Logger";

interface MessageModel
{
    source: Transmission,
    message: string,
    id: string
}

const request = gql`
mutation GPTRequest ($userID: String!, $message: String!) {
    gPTRequest(userId: $userID, message: $message)
    {
        success
        message
    }
}`

const chatStore = createStore<MessageModel[]>([
    {
        id: '007',
        message: "Hi! I'm ai bot. Ask me a question.",
        source: "receiver"
    }
], {
    name: 'chat',
})

const pushMessage = createEvent<MessageModel>()

chatStore.on(pushMessage, (state, payload) => {
    state.push(payload);
    return state
})

export const Chat = () => {
    const getdeviceId = () => {
        const id = localStorage.getItem("user_id");
        if(id == null)
        {
            const next_id = uuid();
            localStorage.setItem('user_id', next_id);
            return next_id;
        }
        
        return id;
    };
    
    const id = getdeviceId()
    
    const [ isWait, setWait ] = useState<boolean>(false);
    const [ message, setMessage ] = useState<string>();
    
    const [ gtpRequest ] = useMutation(request);
    
    const chat = useStore(chatStore);
    const onSend = async () => {
        setWait(true);
        const result = await gtpRequest({
            variables: {
                'userID': id, 
                'message': message
            },
            ...LogRequestResult('gtpRequest')
        });
        setWait(false);
        
        if(result.data?.gPTRequest.success)
        {
            pushMessage({
                id: uuid(),
                message: message!,
                source: "sender"
            })
            
            pushMessage({
                id: uuid(),
                message: result.data.gPTRequest.message,
                source: "receiver"
            })
            
            setMessage('')
        }
    }
    
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container justifyContent='center' ml={0} disableEqualOverflow>
                <Grid xs={11.5} sm={11} md={10} sx={{mt:4}}>
                    <Paper elevation={4} >
                        <Box padding={2} height='93vh' display='flex' flexDirection='column'>
                            <Typography variant="h3" >
                                Welcome!
                            </Typography>
                            <Divider />
                            <Paper variant="outlined" sx={{ mt:1, mb: 1, flexGrow: 1, padding: 1, overflow: 'auto' }}>
                                <Grid container direction="column" sx={{paddingY: 1}}>
                                    {chat.map(message => {
                                        switch (message.source) {
                                            case "sender":
                                                return <SenderMessage key={message.id} avatar={<Avatar sx={{ bgcolor: deepOrange[300]}} >You</Avatar>} children={<Typography whiteSpace='pre-line' variant='body2'>{message.message}</Typography>} />
                                            case "receiver":
                                                return <ReceiverMessage key={message.id} avatar={<Avatar sx={{ bgcolor: blue[300]}}>Ai</Avatar>} children={<Typography whiteSpace='pre-line' variant='body2'>{message.message}</Typography>} />
                                        }
                                        return (<></>)
                                    })}
                                </Grid>
                            </Paper>
                            <Box display='flex' >
                                <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                                    <InputLabel>Message</InputLabel>
                                    <OutlinedInput value={message} onChange={e => setMessage(e.target.value)} multiline maxRows={5} label='Message' sx={{ alignItems: 'flex-end' }} endAdornment={
                                        (<IconButton onClick={onSend}><SendIcon /></IconButton>)
                                    } />
                                </FormControl>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={isWait}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}