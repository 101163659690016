import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

const httpLink = createHttpLink({
    uri: '/graphql',
});

export const graphQL_Client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false
    }),
    link: httpLink,
    connectToDevTools: true
});