import React, {useCallback, useState} from "react";
import {Box} from "@mui/material";
import {ReviewDialog} from "../Components/ReviewDialog";
import {useNavigate, useParams} from "react-router-dom";

export const SentReviewReplyPage = () => {

    const navigate = useNavigate();
    const [ isComplete, setComplete ] = useState<boolean>(false);
    let { id } = useParams();
    
    const onClose = useCallback(() => {
        if(isComplete)
            navigate('/dashboard')
    }, [isComplete])
    
    const onSuccess = useCallback(() => {
        setComplete(true)
    }, [])
    
    return (
        <Box display='flex' flex='1' 
             justifyContent='center' 
             alignItems='center'>
            <ReviewDialog reviewID={id} 
                          isOpen={true} 
                          onClose={onClose} 
                          onSuccess={onSuccess} />
        </Box>
    );
}