import React, {useCallback, useEffect, useState} from 'react';

import {useSignOut} from 'react-auth-kit'

import {styled} from '@mui/material/styles';
import {
    Box,
    Toolbar,
    IconButton,
    Button,
    Divider,
    List,
    Select, InputLabel, Stack, MenuItem, LinearProgress, TextField, OutlinedInput, Hidden
} from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';

import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import {MainItems} from './Dashboard/Menu';
import {Outlet} from "react-router-dom";

import {useGetActiveSubject, useGetUserInfo} from "../Requests/DashbordRequests";

import { createStore, createEvent } from 'effector';
import { useStore } from "effector-react";
import { Subject } from "../Types/DashboardTypes";

import { RadioGroup, Radio, FormControl, FormControlLabel  } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import './Dashboard/Styles.css'

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop: string) => prop !== 'open',
// @ts-ignore
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


// @ts-ignore
const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    // @ts-ignore
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

function useSignOutCallback(): [boolean, () => void] {
    const signOutFunc = useSignOut();
    const [process, setProcess] = useState(false)
    
    return [process, async () => {
        setProcess(true);
        signOutFunc();
        setProcess(false);
    }]
}

const updateSubject = createEvent<[Subject[] | undefined, string]>();

export const $selectedSubject = createStore<Subject | null>(null, {
    name: 'selectedSubject'
}).on(updateSubject, (state, args) => {
    return args[0]?.find(item => item.id == args[1])
})

const DialogueBox = (props: { 
    isOpen: boolean, 
    selectedSubjectID: string | undefined,
    subjects: Subject[] | undefined, 
    onClose: () => void, 
    onSubmit: (selected: string) => void 
}) => {
    const [ selectedSubjectID, setSubject ] = useState<string>()
    
    useEffect(() => {
        if(props.selectedSubjectID !== undefined)
            setSubject(props.selectedSubjectID)
    }, [props.selectedSubjectID])
    
    const onSubmit = useCallback(() => {
        props.onSubmit(selectedSubjectID!);
    }, [selectedSubjectID])
    
    return (<Dialog open={props.isOpen}
                    fullWidth={true}>
        <DialogTitle>Select establishment</DialogTitle>
        <DialogContent>
            <FormControl>
                <RadioGroup value={selectedSubjectID}
                            onChange={(event: any, value: string) => {
                                setSubject(value)
                            }}>
                    { props.subjects?.map(value => {
                        const id = value.id;
                        return (<FormControlLabel key={id} value={id} control={<Radio />} label={value.title} />)
                    })}
                </RadioGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button disabled={selectedSubjectID === undefined} onClick={onSubmit}>Ok</Button>
        </DialogActions>
    </Dialog>)
}

export const Dashboard = () => {
    const { loading, error, data } = useGetUserInfo();
    const [ isOpenSelectSubjectPopup, setOpenSelectSubject ] = useState<boolean>(false)
    
    const selectedSubject = useStore($selectedSubject)
    const [signOutProcess, signOutCallback] = useSignOutCallback()
    
    const [open, setOpen] = useState<boolean>(false)
    
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleSelectSubjectOpen = () => {
        setOpenSelectSubject(true);
    };

    const handleSelectSubjectClose = () => {
        setOpenSelectSubject(false)
    }

    useEffect(() => {
        if(data?.subjects)
        {
            if(!selectedSubject)
                updateSubject([data.subjects, data.subjects[0].id])
            else
                updateSubject([data.subjects, selectedSubject.id])
        }
    }, [data])

    const subject = useGetActiveSubject();

    const handleSelectSubjectUpdate = (selectedSubjectID: string) => {
        setOpenSelectSubject(false)
        updateSubject([data?.subjects, selectedSubjectID])
    }
    
    const isLoadingComplete = !subject.loading && subject.data !== undefined;
    const filter = !isLoadingComplete ? 'blur(4px)' : ''
    const pointerEvents = isLoadingComplete ? 'all' : 'none';
    
    const selectedSubjectIsSelector = (data?.subjects.length ?? 0) < 10;
    
    return (
        <Box sx={{display: 'flex'}}>
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '0px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '18px',
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>

                    <Stack direction="row" justifyContent="flex-end" spacing={1} flex={"fit-content"}>
                        <TextField label='Establishment'
                                   sx={{ mt: 2, mb: 1, mr: 1, minWidth: 120, maxWidth: 260 }}
                                   unselectable="on"
                                   className='unselectable'
                                   fullWidth
                                   value={(selectedSubjectIsSelector ? selectedSubject?.id : selectedSubject?.title) ?? ''}
                                   select={selectedSubjectIsSelector}
                                   onChange={(event: { target: { value: string; }; }) => {
                                       updateSubject([data?.subjects, event.target.value])
                                   }}
                                   InputProps={ selectedSubjectIsSelector ? { inputMode: 'none' } : 
                                   {
                                       readOnly: true,
                                       inputMode: 'none',
                                       endAdornment: (<IconButton color="primary"
                                                                  onClick={handleSelectSubjectOpen}>
                                           <EditIcon />
                                       </IconButton>)
                                   }}
                                   variant={"outlined"}
                                   size={"small"}>
                            {data?.subjects.map(value => (<MenuItem key={value.id} value={value.id}>{value.title}</MenuItem>))}
                        </TextField>

                        <Divider orientation="vertical" flexItem />
                        
                        <Button disabled={signOutProcess} sx={{ display: { xs: 'none', sm: 'flex' } }} endIcon={<ExitToAppIcon /> }  onClick={signOutCallback}>Sign out</Button>
                        <IconButton disabled={signOutProcess} onClick={signOutCallback} size="large" sx={{ display: { xs: 'flex', sm: 'none' }, pr:2.5 }} ><ExitToAppIcon /> </IconButton>
                    </Stack >
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </Toolbar>
                <Divider/>
                <List component="nav" >
                    <MainItems isDisable={loading || selectedSubject === undefined} />
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch'
                }}
            >
                <Toolbar/>

                <Box display='contents' >
                    <Box hidden={isLoadingComplete}>
                        <LinearProgress   />
                    </Box>
                    <Box sx={{ 
                        margin: 2, 
                        height: '100%',
                        filter: filter,
                        pointerEvents: pointerEvents
                    }}>
                        <Outlet />
                    </Box>
                </Box>
            </Box>

            <DialogueBox isOpen={isOpenSelectSubjectPopup} 
                         selectedSubjectID={selectedSubject?.id}
                         subjects={data?.subjects} 
                         onClose={handleSelectSubjectClose} 
                         onSubmit={handleSelectSubjectUpdate} />
        </Box>
    );
};