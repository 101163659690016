import {Card, CardContent, Divider, FormControl, FormControlLabel, Grid, Switch, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useStore} from "effector-react";

import {$selectedSubject} from "../Dashboard";
import {useFormik} from "formik";
import {useGetActiveSubject, useUpdateSubject} from "../../Requests/DashbordRequests";
import {ChangingBox, ContentStatus} from "./Base/ChangingBox";
import {grey} from "@mui/material/colors";

import {matchIsValidTel, MuiTelInput} from 'mui-tel-input'

export const SubjectSettingsContent = () => {
    const [ status, setStatus ] = useState<ContentStatus>(ContentStatus.Saved)
    const selectedSubject = useStore($selectedSubject)
    const [ changeSubject ] = useUpdateSubject();
    
    const subject = useGetActiveSubject();

    const formik = useFormik({
        initialValues: subject.data?.subjectById.notificationsSettings ?? {
            sendToPhone: false,
            phoneNumber: '+971'
        },
        onSubmit: values => {            
            setStatus(ContentStatus.Saving);
            return changeSubject({
                variables: {
                    'subjectId': selectedSubject!.id,
                    'input': values
                }
            }).then(() => {
                setStatus(ContentStatus.Saved);
            }).catch(reason => {
                setStatus(ContentStatus.Error);
            });
        }
    });

    useEffect(() => {
        if(!formik.isSubmitting)
            setStatus(formik.dirty ? ContentStatus.Changed : ContentStatus.Saved);
    }, [formik.dirty])
    
    useEffect(() => {
        formik.resetForm({
            values: subject.data?.subjectById.notificationsSettings ?? {
                phoneNumber: '+971',
                sendToPhone: false
            }
        })
    }, [subject.data])
    
    const isValidate = !formik.values.sendToPhone || matchIsValidTel(formik.values.phoneNumber);
    
    // @ts-ignore
    return (<ChangingBox 
        onSubmit={formik.submitForm}
        canSubmit={isValidate}
        status={status}>

        <Grid container rowSpacing={2} columnSpacing={4} justifyContent='flex-start' sx={{ xs: {
                justifyContent: 'space-around'
            }
        }}>
            <Grid item maxWidth={400} width='100%'>
                <Card elevation={4} sx={{ bgcolor: grey[800] }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 24, userSelect: 'none' }} color="text.secondary">
                            Notifications
                        </Typography>
                        <Divider sx={{ mt:1 }} />
                        <FormControl>
                            <FormControlLabel control={<Switch id='sendToPhone' checked={formik.values.sendToPhone} onChange={formik.handleChange} />} 
                                              label='Send to phone' 
                            />
                            <MuiTelInput
                                sx={{ mt: 1 }}
                                defaultCountry="AE"
                                placeholder="Enter phone number"
                                value={formik.values.phoneNumber}
                                label='Phone number'
                                disableDropdown={true}
                                error={!isValidate}
                                onChange={value => {
                                    formik.setFieldValue('phoneNumber', value)
                                }}
                            />
                        </FormControl>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </ChangingBox>)
}