import * as React from 'react';

import { useNavigate, useLocation } from "react-router-dom";

import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard';
import LayersIcon from '@mui/icons-material/Layers';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import RateReviewIcon from '@mui/icons-material/RateReview';


import { IntegrationContent } from './IntegrationContent'
import { MainContent } from './MainContent'
import { SubjectSettingsContent } from './SubjectSettingsContent'

import {useCallback, useEffect, useState} from "react";
import {ReviewsContent} from "./ReviewsContent";

const menu = [
    {
        icon: DashboardIcon,
        title: 'Dashboard',
        path: '',
        element: <MainContent />
    },
    {
        icon: ManageAccountsIcon,
        title: 'Settings',
        path: 'settings',
        element: <SubjectSettingsContent />
    },
    {
        icon: LayersIcon,
        title: 'Integrations',
        path: 'integrations',
        element: <IntegrationContent />
    },
    {
        icon: RateReviewIcon,
        title: 'Reviews',
        path: 'reviews',
        element: <ReviewsContent />
    }
]

export const TakeRoute : () => { path: string; element: JSX.Element }[] = () => {
    return menu.map(value => {
        const path = value.path;
        const element = value.element;
        
        return {
            path,
            element
        }
    })
}

interface MainItemsProps {
    isDisable: boolean
}

export const MainItems = ({isDisable}: MainItemsProps) => {
    const navigate = useNavigate()
    const location = useLocation()
    
    const [currentPath, setCurrentPath] = useState<string>('');

    useEffect(() => {
        const pathname = location.pathname
        if(pathname == '/dashboard' || pathname == 'dashboard/')
        {
            setCurrentPath(menu[0].path)
            return
        }

        for (let i = 1; i < menu.length; i++)
        {
            if(pathname.startsWith('/dashboard/'+menu[i].path))
            {
                setCurrentPath(menu[i].path)
                return
            }
        }
    }, [location])

    return (<React.Fragment>
        {menu.map((data) => (<ListItemButton disabled={isDisable} key={data.path} selected={data.path == currentPath} onClick={() => navigate(data.path) }><ListItemIcon>{<data.icon />}</ListItemIcon><ListItemText primary={data.title} /></ListItemButton>))}
    </React.Fragment>)
}