import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import {AuthProvider} from "react-auth-kit";
import {createTheme, ThemeProvider} from '@mui/material';
import { ApolloProvider } from '@apollo/client';
import {refreshRequest} from "./Requests/LoginRequests";
import {graphQL_Client} from "./GraphQLRoot";

//import {DevSupport} from "@react-buddy/ide-toolbox";
//import {ComponentPreviews, useInitial} from "./dev";


const theme = createTheme({
    palette: {
        mode: 'dark',
    }
});


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    //<React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <ApolloProvider client={graphQL_Client}>
                <AuthProvider
                    authType={"localstorage"}
                    authName={"token"}
                    refresh={refreshRequest}
                    cookieDomain={window.location.hostname}
                    cookieSecure={window.location.protocol === "https:"}
                >
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </AuthProvider>
            </ApolloProvider>
        </ThemeProvider>
    //</React.StrictMode>
);