import {Grid} from '@mui/material';
import * as React from 'react';
import {useEffect} from 'react';
import {GooglePlaceCard, googlePlaceStore} from "./Integrations/GooglePlaceCard";
import {createEvent, createStore} from "effector";
import {ChangingBox, ContentStatus} from "./Base/ChangingBox";
import {useStore} from "effector-react";
import {$selectedSubject} from "../Dashboard";

import {grey} from '@mui/material/colors'
import {LocationInfo} from "../../Types/DashboardTypes";

import {GET_LOCATION_INFO, useUpdateLocationInfo} from "../../Requests/LocationInfoRequests";
import {onSavedEvent} from "../../Events/DashboardEvents";

export const contentStatusStore = createStore<ContentStatus>(ContentStatus.Changed, {
    name: 'integrationContentStatus'
})

const setGoogleMapState = createEvent<LocationInfo>()
const setStatus = createEvent<ContentStatus>();

contentStatusStore.on(setStatus, (state, payload) => payload);
googlePlaceStore.on(setGoogleMapState, (state, payload) => payload);

export const IntegrationContent = () => {
    const status = useStore(contentStatusStore);
    
    const [ updateSubject ] = useUpdateLocationInfo();
    
    const selectedSubject = useStore($selectedSubject)
    const googleMapState = useStore(googlePlaceStore)

    //RESET STATE
    useEffect(() => {
        onSavedEvent()
        setStatus(ContentStatus.Saved);
    }, [selectedSubject])
    
    //SUBMIT
    const onSubmit = () => {
        return updateSubject({
            variables: {
                subjectId: selectedSubject!.id,
                input: {
                    googleMap: googleMapState
                }
            },
            refetchQueries: (result) => {
                if(result.data?.updateLocationsInfo.updateQuery)
                {
                    return result.data?.updateLocationsInfo.updateQuery.map(value => {
                        return {
                            query: GET_LOCATION_INFO,
                            variables: {
                                subjectId: selectedSubject!.id,
                                source: value
                            }
                        }
                    })
                }
                return [];
            }
        }).then(result => {
            onSavedEvent()
            setStatus(ContentStatus.Saved)
        }).catch(reason => setStatus(ContentStatus.Error));
    }
    
    return <ChangingBox
        onSubmit={onSubmit}
        canSubmit={googleMapState.isEnable}
        status={status}>

        <Grid container rowSpacing={2} columnSpacing={4} justifyContent='flex-start' sx={{ xs: {
                justifyContent: 'space-around'
            }
        }}>
            <Grid item lg='auto' md='auto' sm='auto' xs={12}>
                <GooglePlaceCard sx={{ bgcolor: grey[800] }} />
            </Grid>
        </Grid>
    </ChangingBox>
}