import {gql, useMutation} from "@apollo/client";
import {createRefresh} from 'react-auth-kit'
import {LogRequestResult} from "../Logger";
import {RefreshTokenCallbackResponse} from "react-auth-kit/dist/types";
import {graphQL_Client} from "../GraphQLRoot";


const AUTH_QUERY = gql`
    mutation Auth($email: String!, $password: String!) {
        auth(email: $email, password: $password) {
            success
            error {
                target {
                    isLogin
                    isPassword
                }
                message
            }
            accessToken
            refreshToken
            expiresIn
        }
    }
`;


const REFRESH_QUERY = gql`
    mutation Refresh($refreshToken: String!, $accessToken: String!) {
        refresh(refreshToken: $refreshToken, accessToken: $accessToken) {
            success
            error {
                target {
                    isLogin
                    isAccessToken
                    isRefreshToken
                }
                message
            }
            accessToken
            expiresIn
        }
    }
`;

export interface AuthError {
    message: string,
    target: {
        isLogin: boolean,
        isPassword: boolean
    }
}

export interface AuthResponse {
    auth : {
        success: boolean,
        error?: AuthError,
        accessToken?: string,
        refreshToken?: string,
        expiresIn: number
    }
}


export interface RefreshResponse {
    refresh : {
        success: boolean,
        error?: {
            message: string,
            target: {
                isLogin?: boolean,
                isAccessToken?: boolean,
                isRefreshToken?: boolean
            }
        },
        accessToken?: string,
        expiresIn: number
    }
}

export const useAuthRequest = () => {
    return useMutation<AuthResponse>(AUTH_QUERY, LogRequestResult('auth'))
}

const RefreshToken = async (param: { authToken?: string, refreshToken?: string }): Promise<RefreshTokenCallbackResponse> => {
    const response = await graphQL_Client.mutate<RefreshResponse>({
        mutation: REFRESH_QUERY,
        variables: {
            accessToken: param.authToken,
            refreshToken: param.refreshToken,
        },
        ...LogRequestResult('refreshAuth')
    })
    
    if(response.data?.refresh.success)
    {
        return {
            isSuccess: true,
            newAuthToken: response.data.refresh.accessToken!,
            newAuthTokenExpireIn: response.data.refresh.expiresIn,
            newRefreshTokenExpiresIn: 129600
        }
    }
    
    return {
        isSuccess: false,
        newAuthToken: ''
    }
};

export const refreshRequest = createRefresh({
    interval: 10,
    refreshApiCallback: RefreshToken
});

export const useRefresh = () => {
    return useMutation<RefreshResponse>(REFRESH_QUERY, LogRequestResult('refreshAuth'));
}